import 'src/common/builder';
import 'src/modules/LandingV2';

import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import builder, { BuilderComponent } from '@builder.io/react';
import { isEmpty, keyBy } from 'lodash';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { NextPageContext } from 'next/types';
import { connect, useSelector } from 'react-redux';

import {
  setInverted,
  setProtected,
  unsetInverted,
  unsetProtected,
} from 'src/actions/app';
import { fetchExpertClasses } from 'src/actions/expertClasses';
import { CountryCodes } from 'src/common/enums';
import { useCountry } from 'src/common/hooks/useConfig';
import isServer from 'src/common/isServer';
import { SupersonicBanner } from 'src/components/Banner/SupersonicBanner';
import GlintsContainer from 'src/components/GlintsContainer';
import { InView } from 'src/components/InView';
import { MetadataRenderer } from 'src/components/MetaDescription/MetadataRenderer';
import { State } from 'src/global/store';
import { getCountryWithSGFallback } from 'src/modules/ClientConfig/Selectors';
import { HierarchicalJobCategory } from 'src/modules/Profile/graphql/hierarchicalJobCategories';
import { getIsSupersonicBannerEnabled } from 'src/modules/Unleash/Selectors';
import { MeValue } from 'src/reducers/user/me';
import { getUser } from 'src/selectors/user';

import { fetchLandingCounterNumbers } from '../Actions';
import { CareerGridCategoryPositions } from '../Components/constants';
import { GoogleLogoSchema } from '../Components/GoogleLogoSchema';
import SectionExpertClass from '../Components/SectionExpertClass';
import { SectionOmniSearch } from '../Components/SectionOmniSearch';
import SectionOneStop from '../Components/SectionOneStop/SectionOneStop';
import SectionSignUpCTA from '../Components/SectionSignUpCTA';
import { LandingCounterNumbers } from '../interfaces';
import {
  getExpertClasses,
  getLandingCounterNumbers,
  getOpportunitiesCount,
} from '../Selector';
import {
  Container,
  LandingBackground,
  LandingBackgroundSectionContainer,
  PressFeatures,
  Testimonials,
  ValueProposition,
} from './LandingPsychFlat.sc';

const CareersGrid = dynamic(
  () =>
    import(
      /* webpackChunkName: "LandingComponentsCareersGrid" */ '../Components/CareersGrid'
    )
);
const Footer = dynamic(() => import('src/components/Footer/FooterPsychFlat'));

const landingPageCategoryQuery = {
  query: gql`
    query getHierarchicalJobCategoriesByIds($ids: [UUID!]!) {
      getHierarchicalJobCategoriesByIds(ids: $ids) {
        id
        name
        slug
      }
    }
  `,
  variables: {
    ids: CareerGridCategoryPositions.map(
      ({ hierarchicalJobCategoryId }) => hierarchicalJobCategoryId
    ),
  },
};

const LandingPsychFlat = ({
  expertClasses,
  me,
  setInverted,
  unsetProtected,
  unsetInverted,
  setProtected,
  fetchExpertClasses,
  content,
  landingPageCategories,
}: Props) => {
  const { data } = useQuery<{
    getHierarchicalJobCategoriesByIds: Pick<
      HierarchicalJobCategory,
      'id' | 'name' | 'slug'
    >[];
  }>(landingPageCategoryQuery.query, {
    variables: landingPageCategoryQuery.variables,

    // landingPageCategories props will be undefined for Client side rendering, so refetch for client side rendering
    // but skip for SSR
    skip: !isEmpty(landingPageCategories),
  });
  const country: CountryCodes = useCountry(null);
  const canShowCareersGrid = country !== CountryCodes.TW;
  const isSuperSonicBannerEnabled = useSelector(getIsSupersonicBannerEnabled);
  const fetchedLandingPageCategories =
    landingPageCategories || data?.getHierarchicalJobCategoriesByIds;

  const isUserLoggedIn = me && me.id;

  useEffect(() => {
    setInverted();
    unsetProtected();
    if (country === CountryCodes.ID) {
      fetchExpertClasses();
    }
    return () => {
      unsetInverted();
      setProtected();
    };
  }, [
    setInverted,
    unsetProtected,
    setProtected,
    unsetInverted,
    fetchExpertClasses,
    country,
  ]);

  const LandingHead = () => (
    <Head>
      <link rel="alternate" hrefLang="x-default" href="https://glints.com" />
      <link rel="alternate" hrefLang="id-id" href="https://glints.com/id" />
      <link rel="alternate" hrefLang="en-id" href="https://glints.com/id/en" />
      <link rel="alternate" hrefLang="en-sg" href="https://glints.com/sg" />
      <link rel="alternate" hrefLang="en-my" href="https://glints.com/my" />
      <link rel="alternate" hrefLang="vi-vn" href="https://glints.com/vn" />
      <link rel="alternate" hrefLang="en-vn" href="https://glints.com/vn/en" />
    </Head>
  );
  if (content) {
    return (
      <>
        <MetadataRenderer isLandingPage={true} />
        <LandingHead />
        <BuilderComponent content={content} model="page" />
      </>
    );
  }

  return (
    <div className="landing-component">
      <MetadataRenderer isLandingPage={true} />
      <LandingHead />

      {isSuperSonicBannerEnabled && <SupersonicBanner />}
      <GoogleLogoSchema />
      <SectionOmniSearch />
      <GlintsContainer>
        {country !== CountryCodes.ID && (
          <React.Fragment>
            {canShowCareersGrid && fetchedLandingPageCategories && (
              <InView>
                <CareersGrid
                  landingPageCategoriesById={keyBy(
                    fetchedLandingPageCategories,
                    'id'
                  )}
                />
              </InView>
            )}
            <InView>
              <ValueProposition />
            </InView>
            <InView>
              <PressFeatures />
            </InView>
            <InView>
              <Testimonials />
            </InView>
          </React.Fragment>
        )}
      </GlintsContainer>
      {country === CountryCodes.ID && (
        <React.Fragment>
          <LandingBackground showSignUpCta={!isUserLoggedIn}>
            <LandingBackgroundSectionContainer>
              <Container>
                <SectionOneStop />
              </Container>
            </LandingBackgroundSectionContainer>
            <LandingBackgroundSectionContainer>
              <GlintsContainer>
                <InView>
                  <SectionExpertClass expertClasses={expertClasses} />
                </InView>
              </GlintsContainer>
            </LandingBackgroundSectionContainer>
          </LandingBackground>

          <If condition={!isUserLoggedIn}>
            <InView>
              <SectionSignUpCTA />
            </InView>
          </If>
        </React.Fragment>
      )}
      <InView>
        <Footer />
      </InView>
    </div>
  );
};

LandingPsychFlat.getInitialProps = async (context: NextPageContext) => {
  const { reduxStore, apolloClient } = context;
  const dispatch = reduxStore.dispatch;
  const state = reduxStore.getState();
  const country = getCountryWithSGFallback(state);

  let pagePath: string;
  let currentLocale: string;

  if (!isServer) {
    pagePath = window.location.pathname;
  } else {
    pagePath = context.req.url;
  }

  const isID = pagePath.includes('/id');
  const isNoCountry =
    !pagePath.includes('/id') &&
    !pagePath.includes('/sg') &&
    !pagePath.includes('/tw') &&
    !pagePath.includes('/vn');

  if (isID) {
    if (typeof window !== 'undefined') {
      currentLocale = window.__NEXT_DATA__.props.dstContext.clientConfig.LANG;
    } else {
      currentLocale = context.req.dstContext.clientConfig.LANG;
    }
    const { publicRuntimeConfig } = getConfig();
    builder.init(publicRuntimeConfig.BUILDER_API_KEY);
    const content = await builder
      .get('page', {
        url: '/v2',
        userAttributes: { locale: currentLocale },
        options: {
          data: {
            locale: currentLocale,
          },
        },
      })
      .promise();

    return {
      content,
    };
  }

  if (isNoCountry) {
    if (typeof window !== 'undefined') {
      currentLocale = window.__NEXT_DATA__.props.dstContext.clientConfig.LANG;
    } else {
      currentLocale = context.req.dstContext.clientConfig.LANG;
    }
    const { publicRuntimeConfig } = getConfig();
    builder.init(publicRuntimeConfig.BUILDER_API_KEY);
    const content = await builder
      .get('page', {
        url: '/homepage-development',
        userAttributes: { locale: currentLocale },
        options: {
          data: {
            locale: currentLocale,
          },
        },
      })
      .promise();

    return {
      content,
    };
  }

  const fetchLandingStats = () => {
    return dispatch(fetchLandingCounterNumbers());
  };

  if (country !== CountryCodes.ID) {
    fetchLandingStats();
  }

  if (isServer) {
    if (country !== CountryCodes.ID) {
      try {
        const response = await apolloClient.query<{
          getHierarchicalJobCategoriesByIds: Pick<
            HierarchicalJobCategory,
            'id' | 'name' | 'slug'
          >[];
        }>(landingPageCategoryQuery);

        if (response.data) {
          return {
            landingPageCategories:
              response.data.getHierarchicalJobCategoriesByIds,
          };
        }
      } catch (error) {
        console.error(
          'Error fetching getHierarchicalJobCategoriesByIds',
          error
        );
      }
    }
  }

  return {};
};

interface Props
  extends StateProps,
    DispatchProps,
    BuilderPageProps,
    InitialPageProps {}

interface StateProps {
  landingCounterNumbers: LandingCounterNumbers;
  expertClasses: any;
  me: MeValue;
}

interface InitialPageProps {
  landingPageCategories: Pick<
    HierarchicalJobCategory,
    'id' | 'name' | 'slug'
  >[];
}

interface DispatchProps {
  fetchLandingCounterNumbers: () => void;
  setInverted: () => void;
  setProtected: () => void;
  unsetInverted: () => void;
  unsetProtected: () => void;
  fetchExpertClasses: () => void;
}

interface BuilderPageProps {
  content: any;
}

const mapStateToProps = (state: State) => ({
  landingCounterNumbers: getLandingCounterNumbers(state),
  opportunitiesCount: getOpportunitiesCount(state),
  expertClasses: getExpertClasses(state),
  me: getUser(state),
});

const mapDispatchToProps = {
  unsetInverted,
  setProtected,
  setInverted,
  unsetProtected,
  fetchExpertClasses,
  fetchLandingCounterNumbers,
};

export default connect<StateProps, DispatchProps, Record<string, unknown>>(
  mapStateToProps,
  mapDispatchToProps
)(LandingPsychFlat);
